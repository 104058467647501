import React from 'react'
import { navigate } from 'gatsby'
import { useAuthContext } from '../contexts/AuthContext'

export default function PrivateRoute({ component: Component, ...rest }) {
  const [state] = useAuthContext()

  if (typeof window === 'undefined') return null

  switch (state) {
    case 'AUTHENTICATED':
      return <Component {...rest} />

    default:
      navigate('/login')
      return null
  }
}
