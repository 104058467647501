import React from 'react'
import { Router } from '@reach/router'
import MainInner from '../components/MainInner'
import PrivateRoute from '../components/PrivateRoute'
import Seo from '../components/Seo'

export default function Profile() {
  return (
    <Router basepath="/profile">
      <PrivateRoute path="/" component={Inner} />
    </Router>
  )
}

function Inner() {
  return (
    <>
      <Seo title="Profile" />
      <MainInner>
        <h2>Profile</h2>
        <p>
          I'm still deciding if this page is necessary. Send me a{' '}
          <a href="https://twitter.com/kyleshevlin">tweet</a> if you think of
          something that would be useful to have personalized on this platform.
        </p>
      </MainInner>
    </>
  )
}
